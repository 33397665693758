import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import get from 'lodash/get'
import React from 'react'
import Container from '../components/container'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Section from '../components/section'
import Seo from '../components/seo'

class AboutIndex extends React.Component {
  render() {
    const {
      image,
      description,
      mapEmbedUrl,
      contactNumber,
      contactEmail,
      address,
    } = get(this, 'props.data.contentfulAboutUsPage')

    return (
      <Layout location={this.props.location}>
        <Seo title="About Us | The Square Interiors" />
        <Hero title="About Us" />
        <Section id="about">
          <Container>
            <div style={{ display: 'flex', height: 480 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: description?.childMarkdownRemark?.html,
                }}
                style={{ width: '50%', paddingRight: `var(--space-3xl)` }}
              ></div>
              <GatsbyImage
                style={{ height: 'auto', width: '50%' }}
                alt="The Square Interiors"
                image={image.gatsbyImageData}
              />
            </div>
          </Container>
        </Section>
        <Section id="contact" title="Contact Us">
          <Container>
            <div style={{ display: 'flex', height: 480 }}>
              <div style={{ width: '50%', paddingRight: `var(--space-3xl)` }}>
                <h4>Address</h4>
                <p>{address}</p>
                <h4>Email</h4>
                <p>{contactEmail}</p>
                <h4>Phone Number</h4>
                <p>{contactNumber}</p>
              </div>
              <div style={{ width: '50%' }}>
                <iframe
                  src={mapEmbedUrl}
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  title="our-location"
                ></iframe>
              </div>
            </div>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default AboutIndex

export const pageQuery = graphql`
  query AboutUsQuery {
    contentfulAboutUsPage {
      description {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 424
          height: 212
        )
      }
      mapEmbedUrl
      contactEmail
      contactNumber
      address
    }
  }
`
